import './imageInput.css';

import * as React from 'react';
import * as R from 'ramda';
import * as PropTypes from 'prop-types';
import {makeUrlInput} from '../factory';
import FileInput from '../FileInput/FileInput';


const containerDiv = {
  backgroundColor: '#FFFFFF',
  borderRadius:    '10px',
  padding:         '20px',
};

const root = {
  width: '100%',
};


export default class ImageInput extends React.PureComponent {
  static propTypes = {
    label:        PropTypes.string.isRequired,
    onImgChange:  PropTypes.func.isRequired,
    onLinkChange: PropTypes.func.isRequired,
    style:        PropTypes.object,
    className:    PropTypes.string,
    webUrl:         PropTypes.string,
  };

  state = {
    select:    ((['local', 'web'].indexOf(this.props.select) !== -1) && this.props.select)
                || undefined, // 'local' | 'web' | undefined
    webUrl:    (this.props.webUrl !== null && this.props.webUrl !== "") ? this.props.webUrl
                : undefined, // string | undefined
    localFile: undefined, // string | undefined
  };


  onChooseLocal = () => this.setState(R.assoc('select', 'local'));
  onChooseWeb   = () => this.setState(R.assoc('select', 'web'));
  onCancel      = () => {
    this.setState(R.merge(R.__, {
      select:    undefined,
      webUrl:    undefined,
      localFile: undefined,
    }));

    this.props.onImgChange(undefined);
    this.props.onLinkChange(undefined);
  };


  fileChangedHandler = event => {
    if (!event) {
      return;
    }

    // const file = event.target.files[0];
    const file = event.fileData;
    const blob = event.blob;

    this.setState(R.assoc('localFile', file));
    this.props.onImgChange(blob);
  };


  onWebUrlChange = (event) => {
    if (!event) {
      return;
    }

    const value = event.target.value;

    this.setState(R.assoc('webUrl', value));
    this.props.onLinkChange(value);
  };


  render = () => (
    <div style={R.merge(root, this.props.style || {})} className={this.props.className}>
      {this.props.label}
      <div style={R.merge(
        {
          display:        'flex',
          flexDirection:  'row',
          justifyContent: 'space-between',
          alignItems:     'stretch',
          flexWrap:       'wrap',
          transition:     'all .5s ease',
        },
        containerDiv,
      )}>
        { // Choose source
          this.state.select !== 'web' && this.state.select !== 'local' && <div style={{
            display:        'flex',
            flexDirection:  'row',
            alignItems:     'center',
            justifyContent: 'space-around',
            flexGrow:       1,
          }} className='mr-3'>
            <button className="btn btn-primary" onClick={this.onChooseLocal}>Upload an Image</button>
            <button className="btn btn-primary" onClick={this.onChooseWeb}>Link to an Image</button>
          </div>
        }
        { // Web Source
          (this.state.select === 'web' || this.state.select === 'local') && <div style={containerDiv}>
            {
              this.state.select === 'web' && makeUrlInput(
                this.state.webUrl,
                'Image URL',
                'article-title-image-input',
                'ex: https://mobile-qa.kodiakbp.com/files/hbs.jpg',
                this.onWebUrlChange,
                'Please enter a valid URL.',
                'An optional internet address of the image to show with the article',
                'web-input',
              )
            }
            {
              this.state.select === 'local' && <FileInput label=''
                                                          onChange={this.fileChangedHandler}
                                                          fileTypes={'image/png, image/jpg'}/>
            }
            <button className="btn btn-secondary" onClick={this.onCancel}>Clear Selection</button>
          </div>
        }
        { // Local File Source
          this.state.localFile || this.state.webUrl
          ? <img src={this.state.webUrl || URL.createObjectURL(this.state.localFile)}
                 alt='Preview'
                 className='img-preview'/>
          : <span className='no-img-msg'>No Image Selected</span>
        }
      </div>
    </div>
  );
}
